export const educationData = [
    // {
    //     id: 3,
    //     institution: 'Lycée Molay Youssef',
    //     course: 'High school diploma',
    //     startYear: '2016',
    //     endYear: '2017'
    // },
    {
        id: 2,
        institution: "ISTA CASABLANCA",
        course: 'Software Dev Diploma',
        startYear: '2017',
        endYear: '2019'
    },
    {
        id: 1,
        institution: 'FST Settat, Morocco',
        course: 'Bachelor’s in Software Dev',
        startYear: '2019',
        endYear: 'Present'
    },
]