import React from "react";
import ReactDOM from "react-dom";
import GA4React from "ga-4-react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeContextProvider from "./contexts/ThemeContext";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



ReactDOM.render(
  <ThemeContextProvider>
      <App />
  </ThemeContextProvider>,

  document.getElementById("root")
);
try {
  setTimeout(_ => {
    const ga4react = new GA4React("G-3YSKCXPWLL");
    ga4react.initialize().catch(err => console.error(err));
  }, 4000);
} catch (err) {
      console.error(err);
}

reportWebVitals();
