export const socialsData = {
    github: 'https://github.com/HamzaELhaissouf',
    facebook: null,
    linkedIn: 'https://www.linkedin.com/in/hamza-el-haissouf-6910b8189/',
    instagram: 'https://www.instagram.com/ellhaissoufhamza/',
    codepen: null,
    twitter: null,
    // reddit: 'https://www.reddit.com/user/',
    // blogger: 'https://www.blogger.com/',
    // medium: 'https://medium.com/@',
    // stackOverflow: 'https://stackoverflow.com/users/',
    // gitlab: 'https://gitlab.com/',
    // youtube: 'https://youtube.com/'
}