import one from "../assets/svg/projects/one.svg";
import two from "../assets/svg/projects/two.svg";
import three from "../assets/svg/projects/three.svg";
import four from "../assets/svg/projects/four.svg";
import five from "../assets/svg/projects/five.svg";
import six from "../assets/svg/projects/six.svg";
import seven from "../assets/svg/projects/seven.svg";
import eight from "../assets/svg/projects/eight.svg";
import nine from "../assets/svg/projects/nine.svg";
import ten from "../assets/svg/projects/ten.svg";
import eleven from "../assets/svg/projects/eleven.svg";
import twelve from "../assets/svg/projects/twelve.svg";

export const projectsData = [
  {
    id: 1,
    projectName: "Smart factory",
    projectDesc:
      "This Project aims to manage machines and notify the users about the machines status using inner sensors in a realTime matter",
    tags: ["Vuejs", "AWS", "Serverless Js", "Vuetify"],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    image: two,
  },
  {
    id: 2,
    projectName: "New Recruitement",
    projectDesc:
      "This project is a offers and jobs finding website for a closed industry network",
    tags: ["Laravel", "Vuejs", "Vuetify"],
    // code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    // demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
    image: three,
  },
  {
    id: 3,
    projectName: "Sacafer",
    projectDesc:
      "A project for a local moroccan company to manage all their inner projects and also to manipulate services and products ",
    tags: ["Lemon", "ReactJs", "tailwindCss"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: four,
  },
  // {
  //     id: 4,
  //     projectName: 'Android Patient Tracker',
  //     projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
  //     tags: ['Flutter', 'Firebase'],
  //     code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
  //     image: four
  // },
  {
    id: 5,
    projectName: "Sport App",
    projectDesc: "Mobile application to manage training sessions.",
    tags: ["React Native", "Laravel"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: eleven,
  },
  {
    id: 6,
    projectName: "Real Estate Mobile app",
    projectDesc: "A mobile to help clients sell and buy real estate. ",
    tags: ["React Native" , "php" , "Firebase"],
    code: "https://github.com/hhhrrrttt222111/developer-portfolio",
    demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
    image: eight,
  },
//   {
//     id: 7,
//     projectName: "Stock Market App",
//     projectDesc: "A simple stock market API app",
//     tags: ["React", "Redux", "Bootstrap"],
//     code: "https://github.com/hhhrrrttt222111/developer-portfolio",
//     demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
//     image: seven,
//   },
//   {
//     id: 8,
//     projectName: "Car Pooling System",
//     projectDesc:
//       "The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution",
//     tags: ["Flutter", "React"],
//     code: "https://github.com/hhhrrrttt222111/developer-portfolio",
//     demo: "https://github.com/hhhrrrttt222111/developer-portfolio",
//     image: eight,
//   },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
