export const experienceData = [
    {
        id: 3,
        company: 'CDMA',
        jobtitle: 'Software Developer Internship',
        startYear: 'December 2019',
        endYear: 'Mars 2019'
    },
    {
        id: 2,
        company: 'In mind web',
        jobtitle: 'Software developer',
        startYear: 'june 2020',
        endYear: 'December 2020'
    },
    {
        id: 1,
        company: 'StaffOne',
        jobtitle: 'Software developer',
        startYear: 'December 2020',
        endYear: 'Present'
    },
]