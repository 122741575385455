import resume from "../assets/pdf/resume.pdf";
import image from "../assets/me/me1.jpg";

export const headerData = {
  name: "Hamza EL Haissouf",
  title: "Software Developer",
  desciption:
    "A programming language is for thinking about programs, not for expressing programs you've already thought of. It should be a pencil, not a pen.",
  image,
  resumePdf: resume,
};

{
  /* <a href="https://ibb.co/Pxw7GgQ"><img src="" alt="185774042-295066618752387-5342340416090706718-n" border="0"></a> */
}
