import React, { useState, useContext } from "react";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { makeStyles } from "@material-ui/core/styles";
import buyMeCoffe from "../../assets/svg/buyMeCoffe/buyMeCoffe.jpg";
import { ThemeContext } from "../../contexts/ThemeContext";
import "./BuyMeCoffe.css";

function BuyMeCoffe() {
  const [visible, setVisible] = useState(false);

  const { theme } = useContext(ThemeContext);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const useStyles = makeStyles(() => ({
    icon: {
      fontSize: "3rem",
      color: theme.tertiary,
    },
  }));

  const classes = useStyles();

  return (
    <a href='https://www.buymeacoffee.com/hamzaelhaissouf' target='_blank'>
      <div className="BuyMeCoffe">
        <button onClick={scrollToTop} aria-label="Back to top">
          <img src={buyMeCoffe} className="icon" />
        </button>
      </div>
    </a>
  );
}

export default BuyMeCoffe;
